
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import moment from "moment";
import Check from '@material-ui/icons/Check';
import { useIntl } from 'react-intl'
import { Create, UpdateAgreement } from '../redux/AgreementCrud';
import { getSupplierList } from '../../supplier/redux/SupplierCrud';
import { getbanksList } from '../../bank/redux/Crud';
// import { AssetTypesList } from '../../asset_type/redux/AssetTypeCrud'
import { ClientAddessList } from '../../client/redux/ClientCrud'
import _ from "lodash";
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { cleanEmptyObj, SVformatter, removeSpaceCommaformatter, SVRemovePercentageformatter } from '../../../../_metronic/helpers/components/utils'
import CustomCancelButton from "../../../../_metronic/helpers/uikit/CancelButton";
import CircularProgress from '@mui/material/CircularProgress'
import { AGREEMENTFIELDS } from "../../../../_metronic/helpers/components/constant"
import { directUSAPICall } from "../../../../_metronic/helpers/components/utils"
// import PageLoader from "../../../../_metronic/helpers/components/PageLoader";
import { useSelector } from "react-redux";
import {
  ScrollTopComponent
} from '../../../../_metronic/assets/ts/components';
import FilterSelection from '../../../../_metronic/helpers/components/Filter';

const useStyles = makeStyles(theme => ({
  labelContainer: {
    "& $alternativeLabel": {
      fontSize: '20px'
    }
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Agreement Info', 'Financial Info', 'Documents', 'Notes'];
}

export default function CreateForm(props) {
  const { selectedAGId, page } = props;
  const classes = useStyles();
  const intl = useIntl()
  const history = useHistory()
  const urlParams = useParams()
  const [loading, setLoading] = useState(false)
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [skipped, setSkipped] = React.useState(new Set());
  const [partnersValues, setPartnersValues] = React.useState([]);
  const [suppliersValues, setSuppliersValues] = React.useState([]);
  const [clientsValues, setClientsValues] = React.useState([]);
  // const [assetTypesValues, setAssetTypesValues] = React.useState([]);
  const [uploadedFiles, setUploadedFils] = useState([])
  const { data } = useSelector(({ auth }) => auth.user);
  const [newUploadedFils, setNewUploadedFils] = useState([])
  const [pageLoading, setPageLoading] = useState(true);
  const [currentpage, setCurrentpage] = useState('');
  const { pathname } = useLocation();
  const [userInfo, setUserInfo] = useState({});
  const [agreementList, setAgreementList] = useState([]);
  const [values, setValues] = React.useState({
    id: 0,
    status: 'published',
    heading: '',
    supplier: '',
    agrement_info_no: '',
    type_of_leasing: '',
    typeofaggrements: '',
    lease_start_date: null,
    partner: '',
    client: '',
    no_of_month_lease: '',
    billing_period: '',
    ag_status: '',
    sum_purchase_value_budget: '',
    sum_purchase_value: null,
    invoicing_to: '',
    remaining_credit: null,
    original_sign_date: null,
    founding: '',
    type_of_product: '',
    client_interest: '',
    montly_payment_ratio: null,
    montly_payment_rent_or_lease: '',
    project: '',
    salesperson: '',
    notice_period_before_EOL: '',
    EOL_percentage: null,
    latest_notice_day: '',
    EOL_value_amt: null,
    notice_before_notice_month: '',
    clients_attn_notice: '',
    notice_before_notice_day: '',
    notice: '',
    Interim_income: '',
    clients_attn: null,
    newUploadedFils: [],
    files: [],
    enable_EOL_choice: false,
    EOL_no_reminder: false,
    notice_before_eol: 0,
    EOL_status: '',
    interim_percentage: '',
    no_of_refurbished_units: 1,
    agreement_type: 'REGULAR'
  });
  const steps = getSteps();
  const [isDisable, setIsDisable] = useState(false);
  const [loggedUserRole, setLoggedUserRole] = useState();
  const [hideColumns, setHideColumns] = useState(false);
  const [existErrorMsg, setExistErrorMsg] = useState([]);
  const [showFields, setShowFields] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isPartner, setIsPartner] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [description, setDescription] = useState("")
  const [errValues, setErrValues] = useState({});
  const [bankLists, setbankLists] = useState([]);
  const [isOwnOverview, setisOwnOverview] = useState(null)
  const location = useLocation()
  const [refurbishedModels, setRefurbishedModels] = useState([])

  useEffect(() => {
    if (userInfo) {
      if (userInfo && userInfo.role?.name === 'admin_user' || userInfo.role?.name === 'master_admin') {
        setShowFields(true)
        setIsAdmin(true)
      }
      if (userInfo.role?.name === 'client_admin' || userInfo.role?.name === 'client_user') {
        setIsClient(true)
      }
      if (userInfo.role?.name === 'partner_admin' || userInfo.role?.name === 'partner_user') {
        setIsPartner(true)
      }
    }
  }, [userInfo])

  const scrollTop = () => {
    ScrollTopComponent.goTop()
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Step1
          setValues={setValues}
          values={values}
          isDisable={isDisable}
          userInfo={userInfo}
          partnersValues={partnersValues}
          clientsValues={clientsValues}
          suppliersValues={suppliersValues}
          bankLists={bankLists}
          // assetTypesValues={assetTypesValues}
          hideColumns={hideColumns}
          showFields={showFields}
          agreementList={agreementList}
          isClient={isClient}
          isPartner={isPartner}
          isAdmin={isAdmin}
          setErrValues={setErrValues}
          errValues={errValues}
          refurbishedModels={refurbishedModels}
        />;
      case 1:
        return <Step2
          isDisable={isDisable}
          setValues={setValues}
          values={values}
          hideColumns={hideColumns}
          showFields={showFields}
          isClient={isClient}
          isPartner={isPartner}
          isAdmin={isAdmin}
        />;
      case 2:
        return <Step3
          isDisable={isDisable}
          values={values}
          setUploadedFils={setUploadedFils}
          setNewUploadedFils={setNewUploadedFils}
          showFields={showFields}
          isClient={isClient}
          isPartner={isPartner}
          isAdmin={isAdmin}
        />;
      case 3:
        return <Step4
          setDescription={setDescription}
          isDisable={isDisable}
          values={values}
          setValues={setValues}
        />;
      default:
        return 'Unknown step';
    }
  }

  function totalSteps() {
    return getSteps().length;
  }

  function isStepOptional(step) {
    return step === 1;
  }

  function skippedSteps() {
    return skipped.size;
  }

  function completedSteps() {
    return completed.size;
  }

  function allStepsCompleted() {
    return completedSteps() === totalSteps() - skippedSteps();
  }

  function isLastStep() {
    return activeStep === totalSteps() - 1;
  }

  function handleNext() {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
        // find the first step that has been completed
        steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  const handleStep = step => () => {
    setActiveStep(step);
  };

  function handleReset() {
    setActiveStep(0);
    setCompleted(new Set());
    setSkipped(new Set());
  }

  function isStepSkipped(step) {
    return skipped.has(step);
  }

  function isStepComplete(step) {
    return completed.has(step);
  }

  const handleSubmit = async (type) => {
    let des = ''
    if (description) {
      // let notsss = []
      values.notes = description;
      // values.notes = des
    }

    if (!values.agrement_info_no) {
      return;
    }
    values.newUploadedFils = newUploadedFils;
    if (values.agrement_info_no && values.type_of_leasing && values.lease_start_date && (values.no_of_month_lease || values.no_of_refurbished_units) && values.client && values.partner) {
      delete values.assets;
      delete values.user_updated;
      delete values.user_created;
      delete values.date_created;
      delete values.date_updated;
      // delete values.montly_payment_rent_or_lease;
      // delete values.sum_purchase_value;
      // delete values.EOL_value_amt;
      // delete values.Interim_income;
      // delete values.remaining_credit;
      // values.sum_purchase_value_budget = values.sum_purchase_value_budget.toLocaleString('sv-Se', { currency: 'SEK', style: 'currency' });

      if (values.founding) {
        values.founding = SVRemovePercentageformatter(values.founding)
      }
      if (values.client_interest) {
        values.client_interest = SVRemovePercentageformatter(values.client_interest)
      }
      if (values.montly_payment_ratio) {
        values.montly_payment_ratio = SVRemovePercentageformatter(values.montly_payment_ratio)
      }

      if (values.remaining_credit) {
        values.remaining_credit = SVRemovePercentageformatter(values.remaining_credit)
      }

      if (isOwnOverview === 'true') {
        values.isclientownaggreement = true
      }


      // if (values.residual_value) {
      //   values.residual_value = removeSpaceCommaformatter(values.residual_value)
      // }
      if (values.sum_purchase_value) {
        values.sum_purchase_value = parseInt(removeSpaceCommaformatter(values.sum_purchase_value));
      }
      if (values.sum_purchase_value_1) {
        values.sum_purchase_value_1 = parseInt(removeSpaceCommaformatter(values.sum_purchase_value_1));
      } else {
        values.sum_purchase_value_1 = null
      }
      if (values.sum_purchase_value_budget) {
        values.sum_purchase_value_budget = removeSpaceCommaformatter(values.sum_purchase_value_budget)
      }
      if (values.montly_payment_rent_or_lease) {
        values.montly_payment_rent_or_lease = removeSpaceCommaformatter(values.montly_payment_rent_or_lease)
      }
      // if (values.montly_payment_ratio) {
      //   values.montly_payment_ratio = ((values.montly_payment_ratio/100) * 100)
      // }
      if (values.EOL_value_amt) {
        values.EOL_value_amt = removeSpaceCommaformatter(values.EOL_value_amt)
      }
      if (values.Interim_income) {
        values.Interim_income = removeSpaceCommaformatter(values.Interim_income)
      }
      if (values.invoiced_to_bank) {
        values.invoiced_to_bank = removeSpaceCommaformatter(values.invoiced_to_bank)
      }


      // console.log("valuesssss", (values))
      // return
      setLoading(true)

      delete values.Interim_income;
      delete values.montly_payment_ratio;
      if (!values.id || values.id === 0 && cleanEmptyObj(values)) {
        if (values.salespersoncustom) {
          values.salesperson = ''
        }
        if (values.salesperson) {
          values.salespersoncustom = ''
        }
        values.total_assets = 0
        values.newUploadedFils = newUploadedFils
        let options = {
          fields: ['id']
        }
        options.filter = {
          "agrement_info_no": {
            _eq: values.agrement_info_no
          }
        }
        const result = await directUSAPICall().items('aggrement').readMany(options);
        if (result.data && result.data.length > 0) {
          setValues({ ...values, agrement_info_no: '' })
          setExistErrorMsg('This Agreement is already exists.');
          scrollTop()
          setLoading(false)
          return
        } else {
          setExistErrorMsg('')
          setLoading(false)
        }
        // return
        if (des) {
          let notss = [];
          notss = [des]
          values.notes = notss;
        }
        Create(cleanEmptyObj(values))
          .then(({ data: { data } }) => {
            // console.log("cleanEmptyObj(values)", cleanEmptyObj(values))
            // if(type === 'saveexit') {
            setLoading(false)
            // groupByAgreement(data.id)
            // updateAgreementAsset(data.agrement_info_no)

            // if (des) {
            //   des.selected_id = data.id
            //   createNotes(des.selected_id)
            // }

            // }
            if (type === 'savenext') {
              // history.push(`/createagreement/${data.id}`)
              values.Interim_income = data.Interim_income
              values.id = data.id
              setValues({ ...values });

              handleNext()
            }
            if (type === 'saveexit') {
              values.id = data.id
              values.Interim_income = data.Interim_income
              setValues({ ...values });
              if (isOwnOverview === 'true') {
                history.push('/leased-aggrement')
              } else {
                history.push('/agreements')
              }
            }
            // setAgreementList(data)
            // setIsloading(false)
            //   dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(true)
            //   setStatus('The login detail is incorrect')
          })
      }
      if (values.id) {
        if (des) {
          if (values.notes) {
            let tempnotes = values.notes

            let notss = [];
            notss = [...tempnotes, des]
            // console.log(tempnotes, "valuess", notss)
            values.notes = notss;
          }
        }
        if (newUploadedFils && newUploadedFils.length > 0) {
          values.files = values.files.concat(newUploadedFils)
        }
        UpdateAgreement(cleanEmptyObj(values), values.id)
          .then(({ data: { data } }) => {
            // console.log("dataaa", data)
            // if(type === 'saveexit') {
            setLoading(false)
            // groupByAgreement(data.id)
            // if (des) {
            //   des.selected_id = data.id
            //   createNotes(des.selected_id)
            // }

            // updateAgreementAsset(data.agrement_info_no)
            if (type === 'savenext') {
              values.id = data.id
              setValues({ ...values });
              handleNext()
            }
            if (type === 'saveexit') {
              values.id = data.id
              setValues({ ...values });
              if (isOwnOverview === 'true') {
                history.push('/leased-aggrement')
              } else {
                history.push('/agreements')
              }
            }
            // setAgreementList(data)
            // setIsloading(false)
            //   dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(true)
            //   setStatus('The login detail is incorrect')
          })
      }

    }

  }


  const createNotes = async (id) => {
    if (description) {
      let result = ""
      let param = {
        description: description,
        selected_id: id,
        collection: "aggrement"
      }
      result = await directUSAPICall().items('notes').createOne(param);
    }
  }


  useEffect(() => {
    if (data && data?.id) {
      setUserInfo(data)
      setLoggedUserRole(data.role.name)

      if (data.role) {
        if (data.role.name === 'client_admin' || data.role.name === 'client_user') {

          const path_name = pathname.split("/")
          setCurrentpage(path_name[1])
          if (path_name[1] === 'createagreement') {
            getClientPartnerList(data.client.id)
          } else {
            getClientList(data?.partner?.id, data?.client?.id)

          }

          values.client = data.client.id;
          setHideColumns(true)
          suppliersData()

          return;
        }
        if (data.role.name === 'partner_admin' || data.role.name === 'partner_user') {
          values.partner = data.partner.id;
          getRefurbishedModels();
        }
        getClientList(data?.partner?.id, data?.client?.id);
        suppliersData()
        agreementLists()
        if (data.role?.name === 'admin_user' || data.role?.name === 'master_admin') {
          getRefurbishedModels();
        }

      }
    }
  }, [data])

  useEffect(() => {
    // setIsloading(true)
    // partnersData()
    if (urlParams && urlParams.id) {
      getAgreementData(urlParams.id)
    }

  }, [urlParams])
  useEffect(() => {
    // setIsloading(true)
    // partnersData()
    if (selectedAGId) {
      getAgreementData(selectedAGId)
    }

  }, [selectedAGId])

  useEffect(() => {
    // setIsloading(true)
    // partnersData()
    if (values.partner) {
      if (userInfo?.role?.name) {
        if (userInfo.role?.name === 'client_admin' || userInfo.role?.name === 'client_user') {
          values.client = userInfo.client.id;
        } else {
          values.client = null;
        }
        getClientList(values.partner, values.client)

      }
    }
  }, [values.partner])

  useEffect(() => {
    if (page) {
      if (page === 'viewagreements') {
        setIsDisable(true)
      } else {
        setIsDisable(false)
      }
    }
  }, [page])

  useEffect(() => {
    if (location) {
      let currentpage = location.pathname.split("/")
      if (currentpage[1] === 'createownagreement') {
        setisOwnOverview('true')
      } else {
        setisOwnOverview('false')
      }
      // setPage(currentpage[1])
    }
  }, [location]);

  const getAgreementData = async (id) => {
    setLoading(true)
    setPageLoading(true)
    // let users = await directUSAPICall().users.me.read();
    // console.log("users", users)
    let fields = `${AGREEMENTFIELDS},interim_commision,bank.interim_percentage,bank.id,files,files.id,files.directus_files_id,files.directus_files_id.id,files.directus_files_id.filename_download,files.directus_files_id.title`
    const result = await directUSAPICall().items('aggrement').readOne(id, { fields: [fields] });
    if (result && result.id) {
      let agData = result
      if (result.partner) {
        agData.partner = result.partner.id
      }
      if (result.client) {
        agData.client = result.client.id
      }
      if (result.supplier) {
        agData.supplier = result.supplier.id
      }
      if (result.bank) {
        agData.interim_percentage = result.bank.interim_percentage
      }
      if (result.bank) {
        agData.bank = result.bank.id
      }

      if (result.clients_attn) {
        agData.clients_attn = result.clients_attn.id
      }
      // if (result.residual_value) {
      //   agData.residual_value = SVformatter(result.residual_value)
      // }
      if (result.sum_purchase_value) {
        agData.sum_purchase_value = SVformatter(result.sum_purchase_value)
      }
      if (result.sum_purchase_value_1) {
        agData.sum_purchase_value_1 = SVformatter(result.sum_purchase_value_1)
      } else {
        agData.sum_purchase_value_1 = null
      }
      if (result.sum_purchase_value_budget) {
        agData.sum_purchase_value_budget = SVformatter(result.sum_purchase_value_budget)
      }
      if (result.montly_payment_rent_or_lease) {
        agData.montly_payment_rent_or_lease = SVformatter(result.montly_payment_rent_or_lease)
      }
      // if (result.montly_payment_ratio) {
      //   result.montly_payment_ratio = ((result.montly_payment_ratio/100) * 100)
      // }
      if (result.EOL_value_amt) {
        agData.EOL_value_amt = SVformatter(result.EOL_value_amt)
      }
      if (result.Interim_income) {
        agData.Interim_income = SVformatter(result.Interim_income)
      }
      if (result.invoiced_to_bank) {
        agData.invoiced_to_bank = SVformatter(result.invoiced_to_bank)
      }
      if (result.refurbished_model) {
        agData.refurbished_model = result.refurbished_model.id
      }
      agData.lease_start_date = moment(result.lease_start_date).format('YYYY-MM-DD')
      agData.original_sign_date = result.original_sign_date ? moment(result.original_sign_date).format('YYYY-MM-DD') : null
      agData.eol_date = result.eol_date ? moment(result.eol_date).format('YYYY-MM-DD') : null
      agData.notice_before_notice_day = result.notice_before_notice_day ? moment(result.notice_before_notice_day).format('YYYY-MM-DD') : null
      agData.latest_notice_day = result.latest_notice_day ? moment(result.latest_notice_day).format('YYYY-MM-DD') : null
      setValues(agData)
      setLoading(false)
      setPageLoading(false)
    } else {
      setValues({})
      setLoading(false)
      setPageLoading(false)
    }
  }

  const suppliersData = () => {
    let queryParam = "?sort=supplier_name&filter[supplier_org_no][_nnull]=true&fields=supplier_org_no,supplier_name,id"
    getSupplierList(queryParam)
      .then(({ data: { data } }) => {
        setSuppliersValues(data)
        bankData()
      })
      .catch(() => {
        bankData()
      })
  }
  const bankData = () => {
    let queryParam = "?sort=bank_name&filter[bank_org_no][_nnull]=true&fields=bank_org_no,bank_name,id"
    getbanksList(queryParam)
      .then(({ data: { data } }) => {
        setbankLists(data)
      })
      .catch(() => {
      })
  }


  const agreementLists = async () => {
    let options = {
      limit: -1,
      sort: '-id',
      fields: ['id,agrement_info_no,heading']
    }
    if (userInfo.role) {
      if ((userInfo.role?.name === "client_admin") || (userInfo.role?.name === "client_user")) {
        options.filter = {
          "user_created": {
            "client": {
              _eq: userInfo.client.id
            }
          }
        }
      }
      if (userInfo.role?.name === 'partner_admin' || userInfo.role?.name === 'partner_user') {
        // console.log("users_details", users_details)
        options.filter = {
          "partner.id": {
            _eq: userInfo.partner.id
          }
        }
      }
    }
    const result = await directUSAPICall().items('aggrement').readMany(options);
    if (result.data && result.data.length > 0) {
      setAgreementList(result.data)

    } else {
      setAgreementList([])
    }
  }

  const getClientPartnerList = async (client) => {
    let fields = 'client.name,client.id,client.clients_org_no,partner.partner_org_no,partner.partner_name,partner.id'

    let options = {
      limit: -1,
      fields: [fields]
    }
    options.filter = {
      "user_created": {
        "client": {
          _eq: client
        }
      }
    }

    const result = await directUSAPICall().items('client_address').readMany(options);
    if (result.data && result.data.length > 0) {
      setPageLoading(false)
      let partners = result.data.map(
        (item) => item.partner && item.partner
      );
      partners = partners.filter(function (e) { return e != null; });
      partners = _.orderBy(partners, ['partner_name'], ['asc']);
      // console.log("partners", partners)
      setPartnersValues(_.uniqBy(partners, 'id'))

    } else {
      setPageLoading(false)

      setPartnersValues([])
    }
  }

  // console.log("valuessss", values)
  const getClientList = (partnerId = null, client = null) => {
    let queryFields = 'client.name,client.id,client.clients_org_no,partner.partner_org_no,partner.partner_name,partner.id'
    let filter = ''
    if (partnerId) {
      filter = `?[partner][_nnull]=true&filter[partner][_eq]=${partnerId}&fields=`
    }
    if (client) {
      filter = `?[client][_nnull]=true&filter[client][_eq]=${client}&fields=`
    }

    // console.log("clienttss", filter)
    ClientAddessList(queryFields, filter)
      .then(({ data: { data } }) => {
        setPageLoading(false)

        if (data && data.length > 0) {
          if (!partnerId) {
            let partners = data.map(
              (item) => item.partner && item.partner
            );
            partners = _.orderBy(partners, ['partner_name'], ['asc']);
            partners = partners.filter(function (e) { return e != null; });
            setPartnersValues(_.uniqBy(partners, 'id'))

          }
          if (partnerId || client) {
            // let partners = data.map(
            //   (item) => item.partner && item.partner
            // );
            // partners = _.orderBy(partners, ['partner_name'], ['asc']);
            // setPartnersValues(_.uniqBy(partners, 'id'))
            let clients = data.map(
              (item) => item.client
            );
            clients = _.sortBy(clients, function (o) { return o.name; })
            // clients = _.orderBy(clients, ['name'], ['asc']);
            // values.client = '';
            // setValues({ ...values })
            setClientsValues(_.uniqBy(clients, 'id'))
          }
        }
        // setPartnersValues(data)
        // console.log("dataaa", data)
        // setAgreementList(data)
        // setIsloading(false)
        //   dispatch(auth.actions.login(accessToken))
      })
      .catch((err) => {
        setPageLoading(false)
        throw err;
        // setIsloading(false)
        //   setStatus('The login detail is incorrect')
      })
  }

  const getRefurbishedModels = async () => {
    try {
      let fields = 'id,model,first_year_rent,second_year_rent,third_year_rent,fourth_year_rent';
      let options = {
        limit: -1,
        fields: [fields]
      }
      const result = await directUSAPICall().items('refurbished_model').readMany(options);
      if (result.data && result.data.length > 0) {
        setRefurbishedModels(result.data)
      } else {
        setRefurbishedModels([])
      }
    } catch (err) {
      throw err;
    }

  }


  return (
    <>
      <div>
        <FilterSelection
          title="Create Agreement"
          redirect="agreements"
        />
      </div>
      {existErrorMsg > 0 ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{existErrorMsg}</div>
        </div>
      ) : null}
      <div className="card card-custom gutter-b bg-smoke">
        <div className='card-body'>
          <div className={classes.root}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const buttonProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepButton
                      icon={<Check className={[classes.xiconRoot, index === activeStep ? 'activestep' : ''].join(' ')} />}
                      onClick={handleStep(index)}
                      completed={isStepComplete(index)}
                      {...buttonProps}
                    >
                      <StepLabel
                        classes={{
                          iconContainer: classes.iconContainer
                        }}
                      >
                        {label}
                      </StepLabel>
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              {allStepsCompleted() ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed - you&apos;re finished
            </Typography>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  <form
                    autoComplete="off"
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_signup_form'
                  // onSubmit={handleSubmit}
                  >
                    {getStepContent(activeStep)}
                  </form>

                  <div className="stepper-btn">
                    {
                      !isDisable ?
                        <CustomCancelButton
                          variant="secondary"
                          color="danger"
                          to='agreements'
                        /> : null}

                    <Button variant="contained"
                      color="warning" disabled={activeStep === 0} onClick={handleBack} className={[classes.button, 'backbtn']}>
                      Back
              </Button>
                    {
                      !isDisable ? <>
                        <Button
                          onClick={() => handleSubmit('savenext')}
                          variant="contained"
                          color="primary"
                          // disabled={loading}
                          className={classes.button}
                        >
                          <span className='indicator-label'>{'Save & Next'}</span>
                          {
                            loading && <CircularProgress />
                          }
                        </Button>

                        <Button
                          onClick={() => handleSubmit('saveexit')}
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          className={classes.button}
                        >
                          <span className='indicator-label'>{'Save & Exit'}</span>
                          {
                            loading && <CircularProgress />
                          }

                        </Button></> : <Button
                          onClick={() => handleNext()}
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          className={classes.button}
                        >
                        <span className='indicator-label'>{'Next'}</span>
                      </Button>
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
