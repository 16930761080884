/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ASSETSTATUS, EOL_ACTION ,PRODUCTCATEGORIES} from "../../../../_metronic/helpers/components/constant"
import { getMonthDiff } from "../../../../_metronic/helpers/components/utils"
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: '20px'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));


export default function Step2({
  values,
  setValues,
  assetTypes,
  isDisable,
  agreementData,
  userInfo,
  hideColumns
}) {
  const classes = useStyles();
  const [showEOL, setshowEOL] = useState(false);

  useEffect(() => {
    // console.log("warrentyUntil", warrentyUntil)
    let warrenty_until = '';
    if (values.warrenty_until) {
      warrenty_until = moment(values.warrenty_until, "YYYY-MM-DD");
      var current = moment();
      var monthDiff = warrenty_until.diff(current, "month");
      values.age = monthDiff
      setValues({ ...values })
    }
  }, [values.warrenty_until]);

  useEffect(() => {
    // console.log("warrentyUntil", warrentyUntil)
    if (agreementData.eol_date) {
      if (userInfo?.userType === 'CLIENT') {
        let monthDiff = getMonthDiff(agreementData.eol_date)
        // console.log("props.data", getMonthDiff(props.data.aggrement.eol_date))
        if (monthDiff <= 3) {
          setshowEOL(true)
        }
      } else {
        setshowEOL(true)
      }

    }
  }, [agreementData.eol_date, userInfo]);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <div className="card card-custom gutter-b">
      <div className='card-body box-shadow'>
        <div className="form-group row">
          {<div className='col-lg-6'>
            <TextField
              id="asset_status"
              select
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              className={classes.textField}
              value={values.asset_status}
              name="asset_status"
              onChange={handleChange('asset_status')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Please select Status"
              margin="normal"
            >
              {ASSETSTATUS.map(option => (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>}
           <div className='col-lg-6'>
            <TextField
              label="Grade"
              name="grade"
              id="Processor"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              className={classes.textField}
              value={values.grade}
              onChange={handleChange('grade')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {!hideColumns && <div className='col-lg-6'>
            <TextField
              label="Processor"
              name="Processor"
              id="Processor"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              className={classes.textField}
              value={values.processor}
              onChange={handleChange('processor')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>}
          <div className='col-lg-6'>
            <TextField
              label="Product Groups"
              id="Product Groups"
              name="product_group"
              select
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              className={classes.textField}
              value={values.product_group}
              onChange={handleChange('product_group')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              {PRODUCTCATEGORIES.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div><div className='col-lg-6'>
            <TextField
              id="Brand"
              label="Brand"
              name="brand"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              className={classes.textField}
              value={values.brand}
              onChange={handleChange('brand')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {/* {false && <div className='col-lg-6'>
            <TextField
              label="IMEI"
              id="IMEI"
              name="IMEI"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              className={classes.textField}
              value={values.IMEI}
              onChange={handleChange('IMEI')}
              margin="normal"
            />
          </div>} */}
          <div className='col-lg-6'>
            <TextField
              label="Description"
              name="model"
              id="Model"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              className={classes.textField}
              value={values.model}
              onChange={handleChange('model')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {!hideColumns && <div className='col-lg-6'>
            <TextField
              id="Product description"
              name="product_description"
              label="Product description"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              className={classes.textField}
              value={values.product_description}
              onChange={handleChange('product_description')}
              margin="normal"
            />
          </div>}
          {!hideColumns && <div className='col-lg-6'>
            <TextField
              label="Client own product group"
              name="client_own_product_group"
              id="Client own product group"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              className={classes.textField}
              value={values.client_own_product_group}
              onChange={handleChange('client_own_product_group')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>}
          {!hideColumns && <div className='col-lg-6'>
            <TextField
              label="Memory"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              name="memory"
              id="memory"
              className={classes.textField}
              value={values.memory}
              onChange={handleChange('memory')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>}
          {!hideColumns && <div className='col-lg-6'>
            <TextField
              label="Harddisk"
              name="harddisk"
              id="harddisk"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              className={classes.textField}
              value={values.harddisk}
              onChange={handleChange('harddisk')}
              margin="normal"
            />
          </div>}
          <div className='col-lg-6'>
            <TextField
              label="Screen Size"
              name="screen_size"
              id="screen_size"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              className={classes.textField}
              value={values.screen_size}
              onChange={handleChange('screen_size')}
              margin="normal"
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              id="Extra Info 1"
              name="extra_infor1"
              label="Invoice date"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              className={classes.textField}
              value={values.extra_infor1}
              onChange={handleChange('extra_infor1')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              id="Extra Info 2"
              name="extra_infor2"
              label="Item number"
              className={classes.textField}
              value={values.extra_infor2}
              onChange={handleChange('extra_infor2')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              label="Serial Number"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              name="serial_number"
              id="Serial Number"
              className={classes.textField}
              value={values.serial_number}
              onChange={handleChange('serial_number')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              label="IMEI"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              name="IMEI"
              id="IMEI"
              className={classes.textField}
              value={values.IMEI}
              onChange={handleChange('IMEI')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              id="Order number"
              name="order_number"
              label="Order number"
              className={classes.textField}
              value={values.order_number}
              onChange={handleChange('order_number')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              type={isDisable ? "text" : "date"}
              InputProps={{
                disabled: isDisable
              }, { inputProps: { min: "1999-05-01", max: "2070-05-04" } }}

              variant={isDisable ? "filled" : "standard"}
              margin="normal"
              format="YYYY-MM-DD"
              label="Warrenty until"
              id="Warrenty until"
              name="Warrenty until"
              className={classes.textField}
              value={values.warrenty_until}
              onChange={handleChange('warrenty_until')}
              // formatDate={() => moment().format('YYYY-MM-DD')}
              // defaultValue="2017-05-24"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              label="Insurance company"
              id="Insurance company"
              InputProps={{
                disabled: isDisable
              }}
              variant={isDisable ? "filled" : "standard"}
              name="insurance_company"
              className={classes.textField}
              value={values.insurance_company}
              onChange={handleChange('insurance_company')}
              margin="normal"
            />
          </div>
          {
            false &&
            <div className='col-lg-6'>
              <TextField
                label="Age"
                name="age"
                id="Age"
                className={classes.textField}
                value={values.age}
                // onChange={handleChange('age')}
                margin="normal"
                InputProps={{
                  disabled: true
                }}
                variant={"filled"}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          }
          <div className='col-lg-6'>
            <TextField
              label="Insurance Until"
              name="insurance_until"
              id="Insurance Until"
              type={isDisable ? "text" : "date"}
              InputProps={{
                disabled: isDisable
              }, { inputProps: { min: "1999-05-01", max: "2070-05-04" } }}

              variant={isDisable ? "filled" : "standard"}
              margin="normal"
              format="YYYY-MM-DD"
              className={classes.textField}
              value={values.insurance_until}
              onChange={handleChange('insurance_until')}
              // formatDate={() => moment().format('YYYY-MM-DD')}
              // defaultValue="2017-05-24"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              label="Start Using"
              name="start_using"
              id="startdate"
              type={isDisable ? "text" : "date"}
              InputProps={{
                disabled: isDisable
              }, { inputProps: { min: "1999-05-01", max: "2070-05-04" } }}

              variant={isDisable ? "filled" : "standard"}
              margin="normal"
              format="YYYY-MM-DD"
              className={classes.textField}
              value={values.start_using}
              onChange={handleChange('start_using')}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className='col-lg-6'>
            <TextField
              label="Expected ending"
              name="expected_ending"
              id="date"
              type={isDisable ? "text" : "date"}
              InputProps={{
                disabled: isDisable
              }, { inputProps: { min: "1999-05-01", max: "2070-05-04" } }}

              variant={isDisable ? "filled" : "standard"}
              margin="normal"
              format="YYYY-MM-DD"
              className={classes.textField}
              value={values.expected_ending}
              onChange={handleChange('expected_ending')}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          {
            showEOL &&
            <div className='col-lg-6'>
              <TextField
                id="EOL_action"
                select
                InputProps={{
                  disabled: isDisable
                }}
                variant={isDisable ? "filled" : "standard"}
                className={classes.textField}
                value={values.EOL_action}
                name="EOL_action"
                onChange={handleChange('EOL_action')}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                label="Please select EOL action"
                margin="normal"
              >
                <MenuItem key={'none'} value={''}>
                  {'Please choose'}
                </MenuItem>
                {EOL_ACTION.map(option => (
                  option !== 'Please choose' && <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
